import { Schema } from "@griddo/core";

const schema: Schema.Menu = [
	{
		key: "mainNav",
		display: "Main navigation",
	},
	{
		key: "officesNav",
		display: "Offices navigation",
	},
	{
		key: "footerNav",
		display: "Footer navigation",
	},
	{
		key: "legalNav",
		display: "Legal navigation",
	},
];

export default schema;
