import { LinkLiteProps } from "@autoTypes";
import { Schema } from "@griddo/core";

const schema: Schema.Component<LinkLiteProps> = {
	schemaType: "component",
	component: "LinkLite",
	displayName: "Link",

	configTabs: [
		{
			title: "content",
			fields: [
				{
					_autoType: {
						doc: "/** Url field type */",
					},
					type: "UrlField",
					title: "Url",
					key: "url",
					advanced: true,
				},

				{
					_autoType: {
						doc: "/** Text for the button */",
					},
					type: "TextField",
					title: "Text",
					key: "text",
					placeholder: "Link text",
					humanReadable: true,
				},
			],
		},
	],

	default: {
		component: "LinkLite",
		text: "",
		url: {
			newTab: false,
			noFollow: false,
			href: undefined,
			linkToURL: undefined,
		},
	},
};

export default schema;
