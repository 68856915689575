import { WidgetProps } from "@autoTypes";
import { Schema } from "@griddo/core";
import {
	anchor,
	themeSelector,
	verticalSpacing,
	animation,
	headingTextarea,
	headingType,
	content,
	loremIpsumParagraph,
	subtitle,
	link,
} from "@schemas/presets";

const schema: Schema.Module<WidgetProps> = {
	schemaType: "module",
	component: "Widget",
	category: "content",
	displayName: "Widget",

	configTabs: [
		{
			title: "content",
			fields: [
				{ ...headingTextarea },
				{ ...headingType },
				{ ...subtitle },
				{ ...content },
				{
					title: "Widget 01",
					type: "TextArea",
					key: "widget01",
					placeholder: "Type a script or an iframe",
					mandatory: true,
					helptext: "The code of the script or iframe you need to introduce.",
				},
				{
					title: "Widget 02",
					type: "TextArea",
					key: "widget02",
					placeholder: "Type a script or an iframe",
					hideable: true,
					helptext: "The code of the script or iframe you need to introduce.",
				},
				{
					title: "Iframe height size",
					key: "iframeSize",
					type: "NumberField",
					hideable: true,
					helptext:
						"Enter a value in px if you want the iframe to have a fixed size in height",
				},
				{ ...link },
			],
		},

		{
			title: "config",
			fields: [
				{ ...anchor },
				{
					type: "VisualUniqueSelection",
					title: "Layout",
					key: "layout",
					columns: 2,
					mandatory: true,
					options: [
						{
							value: "layout01",
							img: "/thumbnails/modules/Widget/Layouts/layout01.png",
						},
						{
							value: "layout02",
							img: "/thumbnails/modules/Widget/Layouts/layout02.png",
						},
					],
				},
				{ ...themeSelector },
				{ ...verticalSpacing },
				{ ...animation },
			],
		},
	],

	default: {
		component: "Widget",
		title: "Lorem ipsum",
		tag: "h2",
		subtitle: loremIpsumParagraph,
		content: loremIpsumParagraph,
		widget01: "",
		iframeSize: 0,
		link: { component: "Link" },
		anchorID: null,
		layout: "layout01",
		subtheme: "default",
		verticalSpacing: "medium",
		animation: "none",
	},

	thumbnails: {
		"1x": "/thumbnails/modules/Widget/thumbnail@1x.png",
		"2x": "/thumbnails/modules/Widget/thumbnail@2x.png",
	},
};

export default schema;
