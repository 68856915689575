import { BasicTextProps } from "@autoTypes";
import { Schema } from "@griddo/core";
import {
	anchor,
	animation,
	content,
	defaultLink,
	getThemeOptions,
	heading,
	loremIpsumParagraph,
	mainLink,
	secondaryLink,
	separator,
	subtitle,
	subtitleDecoration,
	themeSelector,
	verticalSpacing,
} from "@schemas/presets";

const schema: Schema.Module<BasicTextProps> = {
	schemaType: "module",
	component: "BasicText",
	category: "content",
	displayName: "Basic Text",

	configTabs: [
		{
			title: "content",
			fields: [
				{ ...heading, mandatory: false, isMockup: true },
				{ ...subtitle },
				{ ...subtitleDecoration },
				{ ...content },
				{ ...mainLink },
				{ ...secondaryLink },
			],
		},
		{
			title: "config",
			fields: [
				{ ...anchor },
				{
					type: "VisualUniqueSelection",
					title: "Layout",
					key: "layout",
					columns: 3,
					mandatory: true,
					options: [
						{
							value: "layout01",
							img: "/thumbnails/modules/BasicText/Layouts/layout01.png",
						},
						{
							value: "layout02",
							img: "/thumbnails/modules/BasicText/Layouts/layout02.png",
						},
						{
							value: "layout03",
							img: "/thumbnails/modules/BasicText/Layouts/layout03.png",
						},
					],
				},
				{
					...themeSelector,
					options: getThemeOptions(["default", "default-alt"]),
				},
				{ ...verticalSpacing },
				{ ...separator },
				{ ...animation },
			],
		},
	],

	default: {
		component: "BasicText",
		title: {
			content: "Lorem ipsum",
			tag: "h2",
		},
		subtitle: loremIpsumParagraph,
		decoration: false,
		content: loremIpsumParagraph,
		link1: { ...defaultLink, text: "Más información", variant: "button2" },
		link2: { component: "Link" },
		anchorID: null,
		layout: "layout01",
		subtheme: "default",
		verticalSpacing: "medium",
		separator: false,
		animation: "none",
	},

	thumbnails: {
		"1x": "/thumbnails/modules/BasicText/thumbnail@1x.png",
		"2x": "/thumbnails/modules/BasicText/thumbnail@2x.png",
	},
};

export default schema;
