import { Schema } from "@griddo/core";

export const POST_CATEGORY: Schema.CategoryContentType = {
	dataPacks: ["GRIDDO_PACK"],
	title: "Post category",
	local: true,
	translate: true,
	taxonomy: true,
	fromPage: false,
};
