import { Schema } from "@griddo/core";

export const POSITION: Schema.CategoryContentType = {
	dataPacks: ["PEOPLE", "OFFICES", "SERVICES"],
	title: "Position",
	local: false,
	translate: true,
	taxonomy: true,
	fromPage: false,
	editable: false,
};
