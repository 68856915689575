import { OFFICESContentTypeProps } from "@autoTypes";
import { Schema } from "@griddo/core";

export const OFFICES: Schema.SimpleContentType<OFFICESContentTypeProps> = {
	dataPacks: ["OFFICES", "SERVICES", "PEOPLE", "INSIGHTS"],
	title: "Offices",
	local: false,
	translate: true,
	clone: null,
	defaultValues: null,
	fromPage: false,
	schema: {
		fields: [
			{
				key: "title",
				title: "Title",
				type: "TextField",
				// mandatory: true,
				readonly: true,
				computed: (simpleData) => {
					return simpleData?.name?.content || "";
				},
			},
			{
				key: "name",
				title: "Office name",
				type: "HeadingField",
				options: [
					{ label: "h1", value: "h1" },
					{ label: "h2", value: "h2" },
					{ label: "h3", value: "h3" },
					{ label: "h4", value: "h4" },
					{ label: "h5", value: "h5" },
					{ label: "h6", value: "h6" },
				],
				default: {
					content: "Lorem ipsum",
					tag: "h2",
				},
				humanReadable: true,
				mandatory: true,
				isMockup: true,
			},
			{
				key: "image",
				title: "Image",
				type: "ImageField",
			},
			{
				key: "location",
				title: "Location",
				type: "TextField",
				mandatory: true,
			},
			{
				key: "additionalInfo",
				title: "Additional info",
				type: "TextField",
			},
			{
				key: "latitude",
				title: "Latitude",
				type: "TextField",
				mandatory: true,
			},
			{
				key: "longitude",
				title: "Longitude",
				type: "TextField",
				mandatory: true,
			},
			{
				key: "phone",
				title: "Phone",
				type: "TextField",
				mandatory: true,
			},
			{
				key: "phone2",
				title: "Phone 2",
				type: "TextField",
			},
			{
				key: "email",
				title: "Email",
				type: "TextField",
				validators: { format: "email" },
				mandatory: true,
			},
			{
				key: "linkedin",
				title: "LinkedIn",
				type: "TextField",
				validators: { format: "URL" },
			},
			{
				key: "x",
				title: "X (Twitter)",
				type: "TextField",
				validators: { format: "URL" },
			},
			{
				key: "link",
				title: "Link",
				type: "UrlField",
				advanced: true,
				mandatory: true,
			},
			{
				key: "divider",
				title: "",
				type: "FieldsDivider",
				data: {
					title: "Select Categories",
					text: "",
				},
			},
			{
				key: "region",
				title: "Region",
				placeholder: "Region",
				type: "MultiCheckSelect",
				source: "REGION",
				mandatory: true,
			},
		],
	},
};
