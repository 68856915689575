import { LinkedinProps } from "@autoTypes";
import { Schema } from "@griddo/core";

const schema: Schema.Component<LinkedinProps> = {
	schemaType: "component",
	component: "Linkedin",
	displayName: "Linkedin",

	configTabs: [
		{
			title: "content",
			fields: [
				{
					title: "LinkedIn",
					key: "value",
					type: "TextField",
					mandatory: true,
					validators: { format: "URL" },
				},
			],
		},
	],

	default: {
		component: "Linkedin",
		value: "",
	},
};

export default schema;
