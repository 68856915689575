import { ImageCardProps } from "@autoTypes";
import { Schema } from "@griddo/core";

const schema: Schema.Component<ImageCardProps> = {
	schemaType: "component",
	component: "ImageCard",
	displayName: "ImageCard",

	configTabs: [
		{
			title: "content",
			fields: [
				{
					type: "HeadingField",
					title: "Title",
					default: { content: "Lorem ipsum", tag: "h2" },
					key: "title",
					placeholder: "Type your title",
					advanced: true,
					helptext: "Write plain text and select the heading type",
					hideable: true,
					isMockup: false,
					mandatory: true,
					options: [
						{ label: "h1", value: "h1" },
						{ label: "h2", value: "h2" },
						{ label: "h3", value: "h3" },
					],
				},

				{
					type: "TextField",
					key: "detail",
					title: "Detail",
				},

				{
					title: "Image",
					type: "ComponentContainer",
					whiteList: ["Image"],
					key: "image",
					helptext: "The image of the card",
				},
			],
		},
	],

	default: {
		component: "ImageCard",
		title: { content: "Lorem ipsum", tag: "h2" },
		image: { component: "Image" },
	},

	thumbnails: {
		"1x": "/thumbnails/templates/ImageCard/thumbnail@1x.png",
		"2x": "/thumbnails/templates/ImageCard/thumbnail@2x.png",
	},
};

export default schema;
