import { EmailProps } from "@autoTypes";
import { Schema } from "@griddo/core";

const schema: Schema.Component<EmailProps> = {
	schemaType: "component",
	component: "Email",
	displayName: "E-mail",

	configTabs: [
		{
			title: "content",
			fields: [
				{
					title: "E-mail",
					key: "value",
					type: "TextField",
					mandatory: true,
					placeholder: "Type an e-mail",
					validators: { format: "email" },
				},
			],
		},
	],

	default: {
		component: "Email",
		value: "",
	},
};

export default schema;
