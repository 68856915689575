import { VerticalTabProps } from "@autoTypes";
import { Schema } from "@griddo/core";
import { heading, content, loremIpsumParagraph, link } from "@schemas/presets";

const schema: Schema.Component<VerticalTabProps> = {
	schemaType: "component",
	component: "VerticalTab",
	displayName: "Vertical Tab",

	configTabs: [
		{
			title: "content",
			fields: [
				{
					type: "TextField",
					title: "Tab title",
					key: "title",
					placeholder: "Type a title",
					isMockup: true,
					mandatory: true,
					humanReadable: true,
				},
				{ ...heading, title: "Content title", key: "contentTitle" },
				{ ...content, title: "Detail", hideable: false, mandatory: true },
				{
					type: "ComponentContainer",
					title: "Visual Content",
					key: "visualContent",
					whiteList: ["Image", "Video"],
					hideable: true,
				},
				{ ...link },
			],
		},
	],

	default: {
		component: "VerticalTab",
		title: "Lorem ipsum",
		contentTitle: {
			content: "Lorem Ipsum",
			tag: "h2",
		},
		content: loremIpsumParagraph,
		visualContent: {
			image: { component: "Image" },
			video: { component: "Video" },
		},
		link: { component: "Link" },
	},
};

export default schema;
