import { PracticeAreaDetailProps } from "@autoTypes";
import { Schema } from "@griddo/core";
import AccordionCollection from "@schemas/modules/AccordionCollection";
import BasicContent from "@schemas/modules/BasicContent";
import Navigation from "@schemas/modules/Navigation";
import {
	headingTextarea,
	headingType,
	loremIpsumParagraph,
} from "@schemas/presets";

const schema: Schema.Template<PracticeAreaDetailProps> = {
	schemaType: "template",
	component: "PracticeAreaDetail",
	displayName: "Practice Area",
	dataPacks: ["SERVICES"],

	type: {
		label: "Practice Area",
		value: "practice_area",
		mode: "detail",
	},

	content: [
		{
			...headingTextarea,
			hideable: false,
			mandatory: true,
			title: "Hero title",
			key: "heroTitle",
		},
		{ ...headingType },
		{
			type: "Wysiwyg",
			full: false,
			title: "Abstract",
			key: "abstract",
			mandatory: true,
			isMockup: true,
			humanReadable: true,
			helptext: "Shown on the distributor and list card",
		},
		{
			type: "ImageField",
			key: "image",
			title: "Image",
			hideable: true,
			helptext: "Shown on the distributor and list card",
		},
		{
			type: "MultiCheckSelectGroup",
			title: "Select Categories",
			key: "categories",
			filled: true,
			mandatory: true,
			elements: [
				{
					placeholder: "Offices",
					key: "offices",
					source: "OFFICES",
				},
			],
		},
		{
			type: "ComponentArray",
			title: "Navigation",
			key: "navigation",
			whiteList: ["Navigation"],
			contentType: "modules",
			maxItems: 1,
		},
		{
			type: "ComponentArray",
			title: "Main Content",
			key: "mainContent",
			whiteList: [
				"AccordionCollection",
				"BasicContent",
				"BasicText",
				"FeaturedFacts",
				"IntroForm",
				"Quote",
				"TextCarousel",
				"Widget",
				"Wysiwyg",
			],
			contentType: "modules",
			mandatory: true,
		},
	],

	default: {
		type: "template",
		templateType: "PracticeAreaDetail",
		heroTitle: "Lorem ipsum",
		tag: "h1",
		abstract: loremIpsumParagraph,
		navigation: {
			component: "Section",
			name: "Navigation",
			modules: [{ ...Navigation.default }],
		},
		mainContent: {
			component: "Section",
			name: "Main Content",
			modules: [
				{ ...BasicContent.default },
				{ ...AccordionCollection.default },
			],
		},
	},

	thumbnails: {
		"1x": "/thumbnails/templates/PracticeAreaDetail/thumbnail@1x.png",
		"2x": "/thumbnails/templates/PracticeAreaDetail/thumbnail@2x.png",
	},
};

export default schema;
