import BasicTemplate from "./BasicTemplate";
import DetailTemplate from "./DetailTemplate";
import Error404 from "./Error404";
import InsightsDetail from "./InsightsDetail";
import ListTemplate from "./ListTemplate";
import NewsDetail from "./NewsDetail";
import PeopleDetail from "./PeopleDetail";
import PeopleList from "./PeopleList";
import PracticeAreaDetail from "./PracticeAreaDetail";
import SectorDetail from "./SectorDetail";
import SectorPracticeAreaList from "./SectorPracticeAreaList";

export default {
	BasicTemplate,
	DetailTemplate,
	Error404,
	InsightsDetail,
	ListTemplate,
	NewsDetail,
	PeopleDetail,
	PeopleList,
	PracticeAreaDetail,
	SectorDetail,
	SectorPracticeAreaList,
};
