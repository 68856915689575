import { Schema } from "@griddo/core";

export const POST: Schema.SimpleContentType = {
	dataPacks: ["GRIDDO_PACK"],
	title: "Post",
	local: true,
	translate: true,
	clone: null,
	defaultValues: null,
	fromPage: false,
	schema: {
		fields: [
			{
				key: "title",
				title: "Title",
				type: "TextField",
				mandatory: true,
			},

			{
				key: "image",
				title: "Image",
				type: "ImageField",
				mandatory: true,
			},

			{
				key: "body",
				title: "Body text",
				type: "TextField",
				mandatory: true,
			},

			{
				key: "link",
				type: "UrlField",
				title: "link",
			},

			{
				title: "Post Cateogory",
				type: "MultiCheckSelect",
				key: "category",
				source: "POST_CATEGORY",
				placeholder: "A placeholder",
			},
		],
	},
};
