//
// Griddo Templates
//
// Every Template must be imported and reexported.
// Yes, a barrel index :)

import * as React from "react";

// Templates
const BasicTemplate = React.lazy(() => import("./BasicTemplate"));
const DetailTemplate = React.lazy(
	() => import("./__Examples__/DetailTemplate"),
);
const Error404 = React.lazy(() => import("./Error404"));
const InsightsDetail = React.lazy(() => import("./InsightsDetail"));
const ListTemplate = React.lazy(() => import("./__Examples__/ListTemplate"));
const NewsDetail = React.lazy(() => import("./__Examples__/NewsDetail"));
const PeopleDetail = React.lazy(() => import("./PeopleDetail"));
const PeopleList = React.lazy(() => import("./PeopleList"));
const PracticeAreaDetail = React.lazy(() => import("./PracticeAreaDetail"));
const SectorDetail = React.lazy(() => import("./SectorDetail"));
const SectorPracticeAreaList = React.lazy(
	() => import("./SectorPracticeAreaList"),
);

// Griddo bundle export
export default {
	BasicTemplate,
	DetailTemplate,
	Error404,
	InsightsDetail,
	ListTemplate,
	NewsDetail,
	PeopleDetail,
	PeopleList,
	PracticeAreaDetail,
	SectorDetail,
	SectorPracticeAreaList,
};
