import { Schema } from "@griddo/core";

export const PRACTICE_AREA: Schema.PageContentType = {
	dataPacks: ["SERVICES"],
	title: "Practice Area",
	local: false,
	fromPage: true,
	translate: true,
	taxonomy: false,
	schema: {
		templates: ["PracticeAreaDetail"],
		fields: [
			{
				type: "TextField",
				title: "Title",
				from: "title",
				key: "title",
				mandatory: true,
			},
			{
				type: "Wysiwyg",
				full: false,
				title: "Abstract",
				from: "abstract",
				key: "abstract",
				mandatory: true,
			},
			{
				type: "ImageField",
				title: "Image",
				from: "image",
				key: "image",
			},
			{
				type: "MultiCheckSelect",
				title: "Offices",
				placeholder: "Offices",
				from: "categories.offices",
				key: "offices",
				source: "OFFICES",
				mandatory: true,
			},
		],
	},
};
