export default {
	global: {
		skipToContent: "Skip to content",
		viewAll: "View all",
		viewMore: "View more",
		and: "and",
		readMore: "Read more",
	},
	a11y: {
		play: "Play video",
		playIcon: "Play icon",
		closeModal: "Close modal",
		closeIcon: "Close icon",
		homeLink: "Link to homepage",
		openMenu: "Open menu",
		menuIcon: "Menu icon",
		contentHide: "Hide content",
		contentShow: "Show content",
		goTo: "Go to",
		readMore: "Read more about",
	},
	Footer: {
		newsletterTitle: "Newsletter",
		connect: "Connect with ECIJA",
		facebook: "Facebook",
		flicker: "Flickr",
		instagram: "Instagram",
		linkedIn: "LinkedIn",
		newsletter: "Newsletter",
		podcast: "Podcast",
		snapchat: "Snapchat",
		tiktok: "TikTok",
		twitter: "X",
		youtube: "YouTube",
	},
	OfficesDistributor: {
		goTo: "Go to office page",
	},
	SectorsAndPracticesDistributor: {
		moreInfo: "More information",
		PRACTICE_AREA: "Practice areas",
		PRACTICE_AREA_mob: "Areas",
		SECTOR: "Sectors",
		SECTOR_mob: "Sectors",
	},
	ShareButton: {
		share: "Share",
	},
	PracticeAreaDetail: {
		areas: "Practice areas",
	},
	Quote: {
		Linkedin: "Go to LinkedIn",
		Facebook: "Go to Facebook",
		Twitter: "Go to X",
		Instagram: "Go to Instagram",
		Youtube: "Go to YouTube",
		Email: "Send e-mail",
		TikTok: "Go to TikTok",
	},
	PeopleDetail: {
		contact: "Contact",
		email: "Email",
		phone: "Phone",
		offices: "Offices",
		services: "Related services",
		insights: "Related insights",
	},
	SectorDetail: {
		sectors: "Sectors",
	},
	PeopleDistributor: {
		goTo: "View profile",
		services: "View professionals by area",
		search: "Search",
		areas: "Practice areas",
		sectors: "Sectors",
	},
	Lists: {
		loading: "Just a few seconds, we are loading the results",
		results: "results found",
		searchLabel: "Search...",
		search: "Search",
		selected: "Selected filters",
		reset: "Delete filters",
	},
	PeopleList: {
		areas: "Practice areas",
		sectors: "Sectors",
		services: "Services",
		position: "Positions",
		offices: "Offices",
		search: "Search for professionals by name...",
	},
	SectorPracticeAreaList: {
		exploreAreas: "Explore our",
		areas: "practice areas",
		exploreSectors: "Explore our",
		sectors: "sectors",
		search: "Search for a service",
		allSearch: "View all",
		allSearch_tab: "View all",
		allSearch_mob: "All services",
		areasSearch: "Practice areas",
		areasSearch_tab: "P. areas",
		areasSearch_mob: "Practice areas",
		sectorsSearch: "Sectors",
		sectorsSearch_tab: "Sectors",
		sectorsSearch_mob: "Sectors",
		services: "All services",
	},
	InsightsDistributor: {
		insights: "Insight categories",
	},
};
