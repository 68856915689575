import { Themes } from "@autoTypes";
import { themes } from "@config";
import { UIFields } from "@griddo/core";
import { Subthemes, subthemes } from "@themes/index";

const getSubthemes = (theme: Themes, s?: Array<Subthemes>) => {
	return subthemes
		.filter((subtheme) => (s ? s.includes(subtheme) : true))
		.map((subtheme) => {
			const img = subtheme
				.split("-")
				.map(
					(text, index) =>
						`${index === 0 ? text[0] : text[0].toUpperCase()}${text.substring(
							1,
						)}`,
				)
				.join("");

			return {
				value: subtheme,
				img: `/thumbnails/themes/${theme}/${img}@2x.png`,
			};
		});
};

export const getThemeOptions = (s?: Array<Subthemes>) => {
	return themes.map((theme) => ({
		theme: theme.value as Themes,
		options: getSubthemes(theme.value as Themes, s),
	}));
};

export const themeSelector: UIFields.VisualUniqueSelection = {
	title: "Style",
	key: "subtheme",
	type: "VisualUniqueSelection",
	columns: 8,
	mandatory: true,
	options: getThemeOptions(),
};
