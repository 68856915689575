import { NavigationProps } from "@autoTypes";
import { Schema } from "@griddo/core";
import { themeSelector } from "@schemas/presets";

const schema: Schema.Module<NavigationProps> = {
	schemaType: "module",
	component: "Navigation",
	displayName: "Navigation",
	singleInstance: true,

	configTabs: [
		{
			title: "content",
			fields: [
				{
					type: "ComponentArray",
					title: "Elements",
					key: "elements",
					whiteList: ["LinkLite"],
					contentType: "components",
					mandatory: true,
				},
			],
		},

		{
			title: "config",
			fields: [{ ...themeSelector }],
		},
	],

	default: {
		component: "Navigation",
		elements: [],
		subtheme: "inverse",
	},

	thumbnails: {
		"1x": "/thumbnails/modules/Navigation/thumbnail@1x.png",
		"2x": "/thumbnails/modules/Navigation/thumbnail@2x.png",
	},
};

export default schema;
