import { WysiwygProps } from "@autoTypes";
import { Schema } from "@griddo/core";
import {
	anchor,
	animation,
	content,
	headingTextarea,
	headingType,
	link,
	loremIpsumParagraph,
	subtitle,
	themeSelector,
	verticalSpacing,
	getThemeOptions,
} from "@schemas/presets";

const schema: Schema.Module<WysiwygProps> = {
	schemaType: "module",
	component: "Wysiwyg",
	category: "content",
	displayName: "Wysiwyg",

	configTabs: [
		{
			title: "content",
			fields: [
				{ ...headingTextarea },
				{ ...headingType },
				{ ...subtitle },
				{ ...content },
				{
					type: "Wysiwyg",
					title: "Wysiwyg 01",
					key: "wysiwyg",
					mandatory: true,
					placeholder: "Type or add visual content",
					humanReadable: true,
				},
				{
					type: "Wysiwyg",
					title: "Wysiwyg 02",
					key: "wysiwyg2",
					hideable: true,
					placeholder: "Type or add visual content",
					humanReadable: true,
				},
				{ ...link },
			],
		},
		{
			title: "config",
			fields: [
				{ ...anchor },
				{
					...themeSelector,
					options: getThemeOptions(["default", "default-alt"]),
				},
				{ ...verticalSpacing },
				{ ...animation },
			],
		},
	],

	default: {
		component: "Wysiwyg",
		title: "Lorem ipsum",
		tag: "h2",
		subtitle: loremIpsumParagraph,
		content: loremIpsumParagraph,
		wysiwyg: loremIpsumParagraph,
		wysiwyg2: null,
		link: { component: "Link" },
		anchor: null,
		subtheme: "default",
		verticalSpacing: "medium",
		animation: "none",
	},

	thumbnails: {
		"1x": "/thumbnails/modules/Wysiwyg/thumbnail@1x.png",
		"2x": "/thumbnails/modules/Wysiwyg/thumbnail@2x.png",
	},
};

export default schema;
