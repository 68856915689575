import { FeaturedFactsProps } from "@autoTypes";
import { Schema } from "@griddo/core";
import FeaturedFact from "@schemas/components/FeaturedFact";
import {
	anchor,
	animation,
	defaultLink,
	getThemeOptions,
	heading,
	link,
	subtitle,
	themeSelector,
	verticalSpacing,
} from "@schemas/presets";

const schema: Schema.Module<FeaturedFactsProps> = {
	schemaType: "module",
	component: "FeaturedFacts",
	category: "content",
	displayName: "Featured Facts",

	configTabs: [
		{
			title: "content",
			fields: [
				{ ...heading, hideable: true, mandatory: false, isMockup: false },
				{ ...subtitle },
				{
					type: "ComponentArray",
					title: "Facts",
					key: "facts",
					whiteList: ["FeaturedFact"],
					contentType: "components",
					maxItems: 4,
					mandatory: true,
					helptext:
						"If a link is added, only the first 3 items will be displayed in desktop.",
				},
				{ ...link },
			],
		},
		{
			title: "config",
			fields: [
				{ ...anchor },
				{
					...themeSelector,
					options: getThemeOptions(["default", "default-alt"]),
				},
				{ ...verticalSpacing },
				{ ...animation },
			],
		},
	],

	default: {
		component: "FeaturedFacts",
		title: null,
		subtitle: null,
		facts: [
			{ ...FeaturedFact.default },
			{ ...FeaturedFact.default },
			{ ...FeaturedFact.default },
			{ ...FeaturedFact.default },
		],
		link: { ...defaultLink, text: "Ver más", variant: "button2" },
		anchorID: null,
		subtheme: "default",
		verticalSpacing: "medium",
		animation: "none",
	},

	thumbnails: {
		"1x": "/thumbnails/modules/FeaturedFacts/thumbnail@1x.png",
		"2x": "/thumbnails/modules/FeaturedFacts/thumbnail@2x.png",
	},
};

export default schema;
