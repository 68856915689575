/* eslint-disable import/order */

// ui schemas
import components from "./src/schemas/components";
import modules from "./src/schemas/modules";
import templates from "./src/schemas/templates";

// config schemas
import {
	languages,
	menuItems,
	menus,
	moduleCategories,
	richText,
	themes,
} from "./src/schemas/config";

// contentTypes schemas
import {
	categoryContentTypes,
	pageContentTypes,
	simpleContentTypes,
} from "./src/schemas/content-types";

// datapacks schemas
import { dataPacks, dataPacksCategories } from "./src/schemas/data-packs";

// bundle
const schemas = {
	all: { ...components, ...modules },
	modules,
	templates,
	dataPacks,
	dataPacksCategories,
	structuredData: {
		...categoryContentTypes,
		...pageContentTypes,
		...simpleContentTypes,
	},
	languages,
	menus,
	menuItems,
	richTextConfig: richText,
};

export { moduleCategories, schemas, themes };
