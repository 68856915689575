// Gatsby SSR API

const griddoWebfonts = (
	<link
		rel="preload"
		href="/webfonts.css"
		as="style"
		// This id is required in order to usen it later in the `builder.browser.js`
		id="griddo-webfonts"
		crossOrigin
		// This onload only works on Griddo Editor (AX)
		// The code to trigger the onload for the build phase (CX) is th builder.browser.js
		onLoad="this.onload=null;this.rel='stylesheet'"
	/>
);

// Este `grissoForAX` solo se carga en AX.
// En AX siempre cargamos todas las calses de Grisso para tener acceso a la biblioteca completa.
const grissoForAX = (
	<link rel="stylesheet" href="/grisso.css" as="style" id="grisso" />
);

/** @type {import("gatsby").GatsbySSR["onRenderBody"]} */
const onRenderBody = ({ setHeadComponents, setBodyAttributes, pathname }) => {
	// common headComponents
	const commonHeadComponents = [griddoWebfonts];

	// Set different scripts for Griddo Builder and Griddo Editor.
	const headComponents =
		pathname === "ax-editor"
			? [...commonHeadComponents, grissoForAX]
			: [...commonHeadComponents];

	setHeadComponents(headComponents);
	setBodyAttributes({ id: "griddo-site" });
};

export default {
	onRenderBody,
};
