import { OtherProps } from "@autoTypes";
import { Schema } from "@griddo/core";

const schema: Schema.Component<OtherProps> = {
	schemaType: "component",
	component: "Other",
	displayName: "Other",

	configTabs: [
		{
			title: "content",
			fields: [
				{
					title: "Other (text)",
					key: "text",
					type: "TextField",
					mandatory: true,
					humanReadable: true,
				},
				{
					title: "Other (link)",
					key: "value",
					type: "TextField",
					mandatory: true,
					validators: { format: "URL" },
				},
			],
		},
	],

	default: {
		component: "Other",
		text: "",
		value: "",
	},
};

export default schema;
