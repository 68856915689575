import { CypherCollectionProps } from "@autoTypes";
import { Schema } from "@griddo/core";
import CypherCard from "@schemas/components/CypherCard";
import {
	anchor,
	themeSelector,
	verticalSpacing,
	animation,
	getThemeOptions,
	link,
	headingType,
	headingTextarea,
} from "@schemas/presets";

const schema: Schema.Module<CypherCollectionProps> = {
	schemaType: "module",
	component: "CypherCollection",
	category: "collection",
	displayName: "Cypher Collection",

	configTabs: [
		{
			title: "content",
			fields: [
				{ ...headingTextarea },
				{ ...headingType },
				{
					type: "ComponentArray",
					title: "Cyphers",
					key: "cyphers",
					whiteList: ["CypherCard"],
					contentType: "components",
					maxItems: 4,
					mandatory: true,
				},
				{ ...link },
			],
		},

		{
			title: "config",
			fields: [
				{ ...anchor },
				{
					type: "VisualUniqueSelection",
					title: "Layout",
					key: "layout",
					columns: 2,
					mandatory: true,
					options: [
						{
							value: "layout01",
							img: "/thumbnails/modules/CypherCollection/Layouts/layout01.png",
						},
						{
							value: "layout02",
							img: "/thumbnails/modules/CypherCollection/Layouts/layout02.png",
						},
						{
							value: "layout03",
							img: "/thumbnails/modules/CypherCollection/Layouts/layout03.png",
						},
					],
				},
				{
					...themeSelector,
					options: getThemeOptions(["default", "default-alt"]),
				},
				{ ...verticalSpacing },
				{ ...animation },
			],
		},
	],

	default: {
		component: "CypherCollection",
		title: "Lorem ipsum",
		tag: "h2",
		cyphers: [
			{ ...CypherCard.default },
			{ ...CypherCard.default },
			{ ...CypherCard.default },
			{ ...CypherCard.default },
		],
		link: { component: "Link" },
		anchorID: null,
		layout: "layout03",
		subtheme: "default-alt",
		verticalSpacing: "medium",
		animation: "none",
	},

	thumbnails: {
		"1x": "/thumbnails/modules/CypherCollection/thumbnail@1x.png",
		"2x": "/thumbnails/modules/CypherCollection/thumbnail@2x.png",
	},
};

export default schema;
