import { LinkProps } from "@autoTypes";
import { Schema } from "@griddo/core";

const schema: Schema.Component<LinkProps> = {
	schemaType: "component",
	component: "Link",
	displayName: "Link",

	configTabs: [
		{
			title: "content",
			fields: [
				{
					_autoType: {
						doc: "/** Url field type */",
					},
					type: "UrlField",
					title: "Url",
					key: "url",
					advanced: true,
				},

				{
					_autoType: {
						doc: "/** Text for the button */",
					},
					type: "TextField",
					title: "Text",
					key: "text",
					placeholder: "Link text",
					humanReadable: true,
				},
			],
		},

		{
			title: "config",
			fields: [
				{
					_autoType: {
						doc: "/** Button variant style */",
					},
					title: "Variant",
					key: "variant",
					type: "VisualUniqueSelection",
					columns: 3,
					options: [
						{ value: "button1", img: "/thumbnails/components/Link/style1.png" },
						{
							value: "button2",
							img: "/thumbnails/components/Link/style2.png",
						},
						{
							value: "button3",
							img: "/thumbnails/components/Link/style3.png",
						},
						{ value: "button4", img: "/thumbnails/components/Link/style4.png" },
					],
				},
			],
		},
	],

	default: {
		component: "Link",
		text: "",
		url: {
			newTab: false,
			noFollow: false,
			href: undefined,
			linkToURL: undefined,
		},
		variant: "button2",
	},
};

export default schema;
