import { Schema } from "@griddo/core";

const schema: Schema.RichTextConfig = {
	paragraphStyles: [
		{
			className: "",
			label: "",
		},
	],
};

export default schema;
