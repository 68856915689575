import { QuoteProps } from "@autoTypes";
import { Schema } from "@griddo/core";
import Linkedin from "@schemas/components/Linkedin";
import { anchor, animation, heading, themeSelector } from "@schemas/presets";

const schema: Schema.Module<QuoteProps> = {
	schemaType: "module",
	component: "Quote",
	category: "content",
	displayName: "Quote",

	configTabs: [
		{
			title: "content",
			fields: [
				{
					title: "Name",
					type: "TextField",
					key: "name",
					hideable: true,
					isMockup: true,
				},
				{
					title: "Position",
					type: "TextField",
					key: "position",
					hideable: true,
					isMockup: true,
					humanReadable: true,
				},
				{
					...heading,
					key: "quote",
					title: "Quote",
					helptext:
						"It is recommended not to use sentences longer than one or two lines in order to maintain the coherence of the design.",
				},
				{
					type: "RadioGroup",
					title: "Text Style",
					key: "textStyle",
					options: [
						{ name: "sans", value: "sans", title: "Sans" },
						{ name: "serif", value: "serif", title: "Serif" },
					],
					mandatory: true,
				},
				{
					type: "RadioGroup",
					title: "Text Size",
					key: "textSize",
					options: [
						{ name: "large", value: "large", title: "Large" },
						{ name: "medium", value: "medium", title: "Medium" },
					],
					mandatory: true,
				},
				{
					type: "ComponentArray",
					title: "Links",
					key: "links",
					contentType: "components",
					whiteList: [
						"Linkedin",
						"Facebook",
						"Twitter",
						"Instagram",
						"Youtube",
						"Other",
						"Email",
						"TikTok",
					],
					maxItems: 6,
				},
			],
		},

		{
			title: "config",
			fields: [{ ...anchor }, { ...themeSelector }, { ...animation }],
		},
	],

	default: {
		component: "Quote",
		name: "Name Lastname",
		position: "Lorem ipsum",
		quote: {
			content: "Lorem ipsum",
			tag: "h2",
		},
		textStyle: "sans",
		textSize: "large",
		links: [{ ...Linkedin.default }],
		anchorID: null,
		subtheme: "default-alt",
		animation: "none",
	},

	thumbnails: {
		"1x": "/thumbnails/modules/Quote/thumbnail@1x.png",
		"2x": "/thumbnails/modules/Quote/thumbnail@2x.png",
	},
};

export default schema;
