import { DetailTemplateProps } from "@autoTypes";
import { Schema } from "@griddo/core";

const schema: Schema.Template<DetailTemplateProps> = {
	schemaType: "template",
	component: "DetailTemplate",
	dataPacks: ["GRIDDO_PACK"],
	displayName: "Detail Template",
	type: {
		label: "A new Detail Template",
		value: "randomValueString",
		mode: "detail",
	},

	content: [
		{
			type: "ImageField",
			title: "Image",
			key: "image",
		},

		{
			type: "AsyncCheckGroup",
			title: "Categories",
			key: "categories",
			source: "NEWS_CATEGORY",
		},

		{
			type: "ComponentArray",
			title: "Main Section",
			key: "mainSection",
			whiteList: ["Collection"],
			contentType: "modules",
		},
	],

	default: {
		type: "template",
		templateType: "DetailTemplate",
		hasDistributorData: true,
		image: undefined,
		categories: undefined,
		mainSection: {
			component: "Section",
			name: "Main Section",
			modules: [],
		},
	},

	thumbnails: {
		"1x": "/thumbnails/templates/DetailTemplate/thumbnail@1x.png",
		"2x": "/thumbnails/templates/DetailTemplate/thumbnail@2x.png",
	},
};

export default schema;
