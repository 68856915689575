import { FAQCollectionProps } from "@autoTypes";
import { Schema } from "@griddo/core";
import FAQ from "@schemas/components/FAQ";
import {
	anchor,
	themeSelector,
	verticalSpacing,
	animation,
	link,
	headingType,
	headingTextarea,
	content,
	loremIpsumParagraph,
	getThemeOptions,
} from "@schemas/presets";

const schema: Schema.Module<FAQCollectionProps> = {
	schemaType: "module",
	component: "FAQCollection",
	category: "collection",
	displayName: "FAQs Collection",

	configTabs: [
		{
			title: "content",
			fields: [
				{ ...headingTextarea },
				{ ...headingType },
				{ ...content },
				{
					type: "FieldGroup",
					title: "Column 1",
					key: "column1",
					fields: [
						{
							type: "ComponentArray",
							title: "Elements",
							key: "elements",
							whiteList: ["FAQ"],
							contentType: "components",
							mandatory: true,
						},
					],
				},
				{
					type: "FieldGroup",
					title: "Column 2",
					key: "column2",
					fields: [
						{
							title: "Would you like to add something else?",
							type: "ConditionalField",
							key: "type",
							mandatory: true,
							options: [
								{
									name: "nothing",
									value: "nothing",
									title: "Nothing else",
								},
								{
									name: "accordion",
									value: "accordion",
									title: "More FAQs",
								},
								{
									name: "visual",
									value: "visual",
									title: "Visual",
								},
							],
							fields: [
								{
									condition: "accordion",
									type: "ComponentArray",
									title: "Elements",
									key: "elements2",
									whiteList: ["FAQ"],
									contentType: "components",
									mandatory: true,
								},
								{
									condition: "visual",
									type: "ComponentContainer",
									title: "Visual Content",
									key: "visualContent",
									whiteList: ["Image", "Video"],
									mandatory: true,
								},
								{
									condition: "visual",
									type: "RadioGroup",
									title: "Visual Content position",
									key: "position",
									options: [
										{ name: "left", value: "left", title: "Left" },
										{ name: "right", value: "right", title: "Right" },
									],
									helptext: "Applies to desktop and big screen resolutions",
									hideable: true,
								},
							],
						},
					],
				},
				{ ...link },
			],
		},

		{
			title: "config",
			fields: [
				{ ...anchor },
				{
					...themeSelector,
					options: getThemeOptions(["default", "default-alt"]),
				},
				{ ...verticalSpacing },
				{ ...animation },
			],
		},
	],

	default: {
		component: "FAQCollection",
		title: "Lorem ipsum",
		tag: "h2",
		content: loremIpsumParagraph,
		elements: [{ ...FAQ.default }, { ...FAQ.default }],
		type: "nothing",
		elements2: [{ ...FAQ.default }, { ...FAQ.default }],
		visualContent: {
			image: { component: "Image" },
			video: { component: "Video" },
		},
		position: "left",
		link: { component: "Link" },
		anchorID: null,
		subtheme: "default-alt",
		verticalSpacing: "medium",
		animation: "none",
	},

	thumbnails: {
		"1x": "/thumbnails/modules/FAQCollection/thumbnail@1x.png",
		"2x": "/thumbnails/modules/FAQCollection/thumbnail@2x.png",
	},
};

export default schema;
