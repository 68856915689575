import { INSIGHT_CATEGORY } from "./INSIGHT_CATEGORY";
import { NEWS_CATEGORY } from "./NEWS_CATEGORY";
import { POSITION } from "./POSITION";
import { POST_CATEGORY } from "./POST_CATEGORY";
import { REGION } from "./REGION";

export default {
	INSIGHT_CATEGORY,
	NEWS_CATEGORY,
	POSITION,
	POST_CATEGORY,
	REGION,
};
