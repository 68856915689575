import { Error404Props } from "@autoTypes";
import { Schema } from "@griddo/core";

const schema: Schema.Template<Error404Props> = {
	schemaType: "template",
	displayName: "Error 404",
	component: "Error404",
	type: { label: "Static", value: "static", special: "404" },
	singleInstance: true,

	content: [
		{
			type: "Wysiwyg",
			full: false,
			title: "Description",
			key: "description",
			placeholder: "Type your description",
		},

		{
			type: "ComponentContainer",
			title: "Image",
			key: "image",
			whiteList: ["FullImage"],
			hideable: true,
		},
	],

	default: {
		type: "template",
		templateType: "Error404",
		description: undefined,
		image: { component: "FullImage" },
	},

	thumbnails: {
		"1x": "/thumbnails/templates/Error404/thumbnail@1x.png",
		"2x": "/thumbnails/templates/Error404/thumbnail@2x.png",
	},
};

export default schema;
