import { NewsDetailProps } from "@autoTypes";
import { Schema } from "@griddo/core";

const schema: Schema.Template<NewsDetailProps> = {
	schemaType: "template",
	component: "NewsDetail",
	displayName: "News Detail",

	type: {
		label: "News detail template",
		value: "newsdetail",
		mode: "detail",
	},

	content: [
		{
			type: "ImageField",
			title: "Image",
			key: "image",
		},

		{
			type: "TextField",
			key: "abstract",
			title: "Abstract",
			placeholder: "Type the abstract text",
		},

		{
			title: "News Category",
			type: "MultiCheckSelect",
			key: "category",
			source: "NEWS_CATEGORY",
			placeholder: "A place holder",
		},

		{
			type: "ComponentArray",
			title: "Main Section",
			whiteList: ["MainHero", "Collection"],
			key: "mainSection",
			contentType: "modules",
		},
	],

	default: {
		type: "template",
		templateType: "NewsDetail",
		mainSection: {
			component: "Section",
			name: "Main Section",
			modules: [],
		},
	},

	thumbnails: {
		"1x": "/thumbnails/templates/NewsDetail/thumbnail@1x.png",
		"2x": "/thumbnails/templates/NewsDetail/thumbnail@2x.png",
	},
};

export default schema;
