import { INSIGHTS } from "./INSIGHTS";
import { NEWS } from "./NEWS";
import { PEOPLE } from "./PEOPLE";
import { PRACTICE_AREA } from "./PRACTICE_AREA";
import { SECTOR } from "./SECTOR";

export default {
	INSIGHTS,
	NEWS,
	PEOPLE,
	PRACTICE_AREA,
	SECTOR,
};
