import { InnerBasicTextProps } from "@autoTypes";
import { Schema } from "@griddo/core";
import {
	animation,
	content,
	defaultLink,
	loremIpsumParagraph,
	mainLink,
	secondaryLink,
	subtitle,
	subtitleDecoration,
} from "@schemas/presets";

const schema: Schema.Module<InnerBasicTextProps> = {
	schemaType: "module",
	component: "InnerBasicText",
	category: "content",
	displayName: "Basic Text",

	configTabs: [
		{
			title: "content",
			fields: [
				{ ...subtitle },
				{ ...subtitleDecoration },
				{ ...content },
				{ ...mainLink },
				{ ...secondaryLink },
			],
		},
		{
			title: "config",
			fields: [
				{
					type: "VisualUniqueSelection",
					title: "Layout",
					key: "layout",
					columns: 3,
					mandatory: true,
					options: [
						{
							value: "layout01",
							img: "/thumbnails/modules/BasicText/Layouts/layout01.png",
						},
						{
							value: "layout02",
							img: "/thumbnails/modules/BasicText/Layouts/layout02.png",
						},
						{
							value: "layout03",
							img: "/thumbnails/modules/BasicText/Layouts/layout03.png",
						},
					],
				},
				{ ...animation },
			],
		},
	],

	default: {
		component: "InnerBasicText",
		subtitle: loremIpsumParagraph,
		decoration: false,
		content: loremIpsumParagraph,
		link1: { ...defaultLink, text: "Más información", variant: "button2" },
		link2: { component: "Link" },
		layout: "layout02",
		animation: "none",
	},

	thumbnails: {
		"1x": "/thumbnails/modules/BasicText/thumbnail@1x.png",
		"2x": "/thumbnails/modules/BasicText/thumbnail@2x.png",
	},
};

export default schema;
