import { BasicHeroProps } from "@autoTypes";
import { Schema } from "@griddo/core";
import {
	heading,
	anchor,
	loremIpsumParagraph,
	subtitle,
	animation,
	themeSelector,
} from "@schemas/presets";

const schema: Schema.Module<BasicHeroProps> = {
	schemaType: "module",
	component: "BasicHero",
	category: "heros",
	displayName: "Basic Hero",

	configTabs: [
		{
			title: "content",
			fields: [
				{ ...heading, hideable: true, mandatory: false },
				{ ...subtitle },
				{
					type: "ComponentContainer",
					title: "Background",
					key: "background",
					whiteList: ["Image", "BackgroundVideo"],
					hideable: true,
				},
			],
		},

		{
			title: "config",
			fields: [
				{ ...anchor },
				{
					type: "VisualUniqueSelection",
					title: "Layout",
					key: "layout",
					columns: 2,
					mandatory: true,
					options: [
						{
							value: "layout01",
							img: "/thumbnails/modules/BasicHero/Layouts/layout01.png",
						},
						{
							value: "layout02",
							img: "/thumbnails/modules/BasicHero/Layouts/layout02.png",
						},
						{
							value: "layout03",
							img: "/thumbnails/modules/BasicHero/Layouts/layout03.png",
						},
					],
				},
				{ ...themeSelector },
				{ ...animation },
				{
					type: "UniqueCheck",
					key: "breadcrumbsDesktop",
					title: "Breadcrumbs Desktop/Tablet",
					options: [{ title: "Display in tablet and desktop" }],
				},
				{
					type: "UniqueCheck",
					key: "breadcrumbs",
					title: "Breadcrumbs Mobile",
					options: [{ title: "Display in mobile" }],
				},
			],
		},
	],

	default: {
		component: "BasicHero",
		title: {
			content: "Lorem ipsum",
			tag: "h2",
		},
		subtitle: loremIpsumParagraph,
		background: {
			image: { component: "Image" },
			backgroundVideo: { component: "BackgroundVideo" },
		},
		anchorID: null,
		layout: "layout01",
		subtheme: "default",
		animation: "none",
		breadcrumbsDesktop: true,
		breadcrumbs: false,
	},

	thumbnails: {
		"1x": "/thumbnails/modules/BasicHero/thumbnail@1x.png",
		"2x": "/thumbnails/modules/BasicHero/thumbnail@2x.png",
	},
};

export default schema;
