export default {
	global: {
		skipToContent: "Saltar al contenido",
		viewAll: "Ver todo",
		viewMore: "Ver más",
		and: "y",
		readMore: "Leer más",
	},
	a11y: {
		play: "Reproducir vídeo",
		playIcon: "Icono de reproducción",
		closeModal: "Cerrar ventana modal",
		closeIcon: "Icono de cerrar",
		homeLink: "Enlace a la página principal",
		openMenu: "Abrir menú",
		menuIcon: "Icono de menú",
		contentHide: "Ocultar contenido",
		contentShow: "Mostrar contenido",
		goTo: "Ir a",
		readMore: "Lee más sobre",
	},
	Footer: {
		newsletterTitle: "Newsletter",
		connect: "Conecta con ECIJA",
		facebook: "Facebook",
		flicker: "Flickr",
		instagram: "Instagram",
		linkedIn: "LinkedIn",
		newsletter: "Newsletter",
		podcast: "Podcast",
		snapchat: "Snapchat",
		tiktok: "TikTok",
		twitter: "X",
		youtube: "YouTube",
	},
	OfficesDistributor: {
		goTo: "Ir a página de sede",
	},
	SectorsAndPracticesDistributor: {
		moreInfo: "Más información",
		PRACTICE_AREA: "Áreas de práctica",
		PRACTICE_AREA_mob: "Áreas",
		SECTOR: "Sectores",
		SECTOR_mob: "Sectores",
	},
	ShareButton: {
		share: "Share",
	},
	PracticeAreaDetail: {
		areas: "Áreas de práctica",
	},
	Quote: {
		Linkedin: "Ir a LinkedIn",
		Facebook: "Ir a Facebook",
		Twitter: "Ir a X",
		Instagram: "Ir a Instagram",
		Youtube: "Ir a YouTube",
		Email: "Enviar correo electrónico",
		TikTok: "Ir a TikTok",
	},
	PeopleDetail: {
		contact: "Contacto",
		email: "Email",
		phone: "Teléfono",
		offices: "Oficinas",
		services: "Servicios relacionados",
		insights: "Insights relacionados",
	},
	SectorDetail: {
		sectors: "Sectores",
	},
	PeopleDistributor: {
		goTo: "Ver ficha de profesional",
		services: "Ver profesionales por área",
		search: "Buscar por nombre",
		areas: "Áreas de práctica",
		sectors: "Sectores",
	},
	Lists: {
		loading: "Sólo unos segundos, estamos cargando los resultados",
		results: "resultados encontrados",
		searchLabel: "Buscar...",
		search: "Buscar",
		selected: "Filtros seleccionados",
		reset: "Borrar filtros",
	},
	PeopleList: {
		areas: "Áreas de práctica",
		sectors: "Sectores",
		services: "Servicios",
		position: "Cargos",
		offices: "Sedes",
		search: "Buscar profesionales por su nombre...",
	},
	SectorPracticeAreaList: {
		exploreAreas: "Explora nuestras",
		areas: "áreas de práctica",
		exploreSectors: "Explora nuestros",
		sectors: "sectores",
		search: "Buscar un servicio",
		allSearch: "Ver todo",
		allSearch_tab: "Ver todo",
		allSearch_mob: "Todos los servicios",
		areasSearch: "Áreas de práctica",
		areasSearch_tab: "Á. de práctica",
		areasSearch_mob: "Áreas de práctica",
		sectorsSearch: "Sectores",
		sectorsSearch_tab: "Sectores",
		sectorsSearch_mob: "Sectores",
		services: "Todos los servicios",
	},
	InsightsDistributor: {
		insights: "Tipo de insights",
	},
};
