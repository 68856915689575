import { SectorsAndPracticesDistributorProps } from "@autoTypes";
import { Schema } from "@griddo/core";
import {
	anchor,
	animation,
	getThemeOptions,
	headingTextarea,
	headingType,
	link,
	themeSelector,
	verticalSpacing,
} from "@schemas/presets";

const schema: Schema.Module<SectorsAndPracticesDistributorProps> = {
	schemaType: "module",
	component: "SectorsAndPracticesDistributor",
	category: "distributors",
	displayName: "Sectors & Practices Distributor",
	dataPacks: ["SERVICES"],
	sectionList: {
		BasicTemplate: ["mainSection"],
	},

	configTabs: [
		{
			title: "content",
			fields: [
				{ ...headingTextarea },
				{ ...headingType },
				{
					type: "ReferenceField",
					title: "Sectors & Practices",
					key: "data",
					sources: [
						{ structuredData: "PRACTICE_AREA" },
						{ structuredData: "SECTOR" },
					],
					mandatory: true,
				},
				{ ...link },
			],
		},

		{
			title: "config",
			fields: [
				{ ...anchor },
				{
					...themeSelector,
					options: getThemeOptions(["default", "default-alt"]),
				},
				{ ...verticalSpacing },
				{ ...animation },
			],
		},
	],

	default: {
		component: "SectorsAndPracticesDistributor",
		title: "Lorem ipsum",
		tag: "h2",
		hasDistributorData: true,
		data: {
			mode: "auto",
			order: "recent-DESC",
			sources: [
				{ structuredData: "PRACTICE_AREA" },
				{ structuredData: "SECTOR" },
			],
			fields: ["title", "abstract", "image"],
		},
		link: { component: "Link" },
		anchorID: null,
		subtheme: "default",
		verticalSpacing: "medium",
		animation: "none",
	},

	thumbnails: {
		"1x": "/thumbnails/modules/SectorsAndPracticesDistributor/thumbnail@1x.png",
		"2x": "/thumbnails/modules/SectorsAndPracticesDistributor/thumbnail@2x.png",
	},
};

export default schema;
