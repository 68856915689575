import { InnerBasicContentProps } from "@autoTypes";
import { Schema } from "@griddo/core";
import {
	animation,
	content,
	loremIpsumParagraph,
	subtitle,
	subtitleDecoration,
	mainLink,
	secondaryLink,
	defaultLink,
} from "@schemas/presets";

const schema: Schema.Module<InnerBasicContentProps> = {
	schemaType: "module",
	component: "InnerBasicContent",
	category: "content",
	displayName: "Basic Content",

	configTabs: [
		{
			title: "content",
			fields: [
				{
					type: "ComponentContainer",
					title: "Visual Content",
					key: "visualContent",
					whiteList: ["Image", "Video", "Map"],
					mandatory: true,
					helptext: "Recommended minimum image size: 792x514px",
				},
				{ ...subtitle },
				{ ...subtitleDecoration },
				{
					title: "Module decoration",
					type: "RadioGroup",
					key: "moduleDecoration",
					options: [
						{ name: "yes", value: true, title: "Yes" },
						{ name: "no", value: false, title: "No" },
					],
					helptext:
						"This behavior will only affect the decoration behind the Visual content",
					mandatory: true,
				},
				{ ...content },
				{ ...mainLink },
				{ ...secondaryLink },
			],
		},

		{
			title: "config",
			fields: [
				{
					type: "VisualUniqueSelection",
					title: "Layout",
					key: "layout",
					columns: 2,
					mandatory: true,
					options: [
						{
							value: "layout01",
							img: "/thumbnails/modules/BasicContent/Layouts/layout01.png",
						},
						{
							value: "layout02",
							img: "/thumbnails/modules/BasicContent/Layouts/layout02.png",
						},
						{
							value: "layout03",
							img: "/thumbnails/modules/BasicContent/Layouts/layout03.png",
						},
						{
							value: "layout04",
							img: "/thumbnails/modules/BasicContent/Layouts/layout04.png",
						},
					],
				},
				{ ...animation },
			],
		},
	],

	default: {
		component: "InnerBasicContent",
		visualContent: {
			image: { component: "Image" },
			video: { component: "Video" },
			map: { component: "Map" },
		},
		subtitle: loremIpsumParagraph,
		decoration: true,
		moduleDecoration: false,
		content: loremIpsumParagraph,
		link1: { ...defaultLink, text: "Más información", variant: "button2" },
		link2: { component: "Link" },
		layout: "layout01",
		animation: "none",
	},

	thumbnails: {
		"1x": "/thumbnails/modules/BasicContent/thumbnail@1x.png",
		"2x": "/thumbnails/modules/BasicContent/thumbnail@2x.png",
	},
};

export default schema;
