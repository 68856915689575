import { UIFields, Fields } from "@griddo/core";

export const link: UIFields.ComponentContainer = {
	type: "ComponentContainer",
	title: "Link",
	key: "link",
	whiteList: ["Link"],
	hideable: true,
};

export const mainLink: UIFields.ComponentContainer = {
	...link,
	title: "Main link",
	key: "link1",
};

export const secondaryLink: UIFields.ComponentContainer = {
	...link,
	title: "Secondary link",
	key: "link2",
};

export const linkLite: UIFields.ComponentContainer = {
	type: "ComponentContainer",
	title: "Link",
	key: "link",
	whiteList: ["LinkLite"],
	hideable: true,
};

type DefaultLinkValues = {
	component: "Link";
	url: Fields.Url;
};

export const defaultLink: DefaultLinkValues = {
	component: "Link",
	url: {
		href: undefined,
		linkToURL: undefined,
		newTab: false,
		noFollow: false,
	},
};

type DefaultLinkLiteValues = {
	component: "LinkLite";
	url: Fields.Url;
};

export const defaultLinkLite: DefaultLinkLiteValues = {
	component: "LinkLite",
	url: {
		href: undefined,
		linkToURL: undefined,
		newTab: false,
		noFollow: false,
	},
};
