import { ListTemplateProps } from "@autoTypes";
import { Schema } from "@griddo/core";

const schema: Schema.ListTemplate<ListTemplateProps> = {
	dataPacks: ["GRIDDO_PACK"],
	schemaType: "template",
	displayName: "List dynamic",
	component: "ListTemplate",
	type: { label: "The items", value: "items", mode: "list" },

	content: [
		{
			type: "ReferenceField",
			title: "Members",
			sources: [{ structuredData: "NEWS" }],
			selectionType: ["auto", "manual"],
			key: "data",
		},

		{
			title: "Items/page",
			type: "NumberField",
			key: "itemsPerPage",
			min: 4,
			max: 40,
			mandatory: true,
		},
	],

	default: {
		type: "template",
		hasDistributorData: true,
		templateType: "ListTemplate",
		activePage: 1,
		itemsPerPage: 6,
		data: {
			sources: [{ structuredData: "NEWS" }],
			mode: "auto",
			/* ---------- */
			/* opcionales */
			/* ---------- */
			// order: 'alpha-ASC',
			// filter: ['string'],
			// fullRelations: true,
			// quantity: 2
		},
	},

	thumbnails: {
		"1x": "/thumbnails/templates/ListTemplate/thumbnail@1x.png",
		"2x": "/thumbnails/templates/ListTemplate/thumbnail@2x.png",
	},
};

export default schema;
