import { Schema } from "@griddo/core";

export default [
	{ label: "Collection", value: "collection" },
	{ label: "Content", value: "content" },
	{ label: "Distributors", value: "distributors", featured: true },
	{ label: "Heros", value: "heros" },
	{
		label: "Interactive",
		value: "interactive",
	},
	{ label: "Spacers", value: "spacers", featured: true },
] as const satisfies Schema.ModuleCategories;
