/**
 * Webfont css delay.
 *
 * When the browser load the page, this `onClientEntry` set the stylesheet rel
 * attribute to "active" the webfonts css.
 * This way when the browser load the html page, webfonst doesn't block the
 * render.
 * @type {import("gatsby").GatsbyBrowser["onInitialClientRender"]}
 */
const onInitialClientRender = () => {
	const griddoWebfonts = document.getElementById("griddo-webfonts");

	if (griddoWebfonts) {
		griddoWebfonts.setAttribute("rel", "stylesheet");
	}
};

export default {
	onInitialClientRender,
};
