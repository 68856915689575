import { IntroFormProps } from "@autoTypes";
import { Schema } from "@griddo/core";
import {
	anchor,
	heading,
	themeSelector,
	verticalSpacing,
	content,
	loremIpsumParagraph,
	subtitle,
	getThemeOptions,
	separator,
	mainLink,
	secondaryLink,
} from "@schemas/presets";

const schema: Schema.Module<IntroFormProps> = {
	schemaType: "module",
	component: "IntroForm",
	category: "content",
	displayName: "Intro + Form",

	configTabs: [
		{
			title: "content",
			fields: [
				{ ...heading, mandatory: false, hideable: true },
				{ ...subtitle },
				{ ...content, mandatory: true, hideable: false },
				{
					title: "Format",
					type: "ConditionalField",
					key: "format",
					helptext: "",
					mandatory: true,
					options: [
						{
							name: "form",
							value: "form",
							title: "Form",
						},
						{
							name: "visualContent",
							value: "visualContent",
							title: "Visual Content",
						},
					],

					fields: [
						{
							condition: "form",
							type: "TextField",
							title: "Form title",
							key: "formTitle",
							hideable: true,
							humanReadable: true,
						},
						{
							condition: "form",
							type: "TextField",
							title: "Portal ID",
							key: "portalId",
							mandatory: true,
						},
						{
							condition: "form",
							type: "TextField",
							title: "Form ID",
							key: "formId",
							mandatory: true,
						},
						{
							condition: "visualContent",
							type: "ComponentContainer",
							title: "Visual Content",
							key: "visualContent",
							whiteList: ["Image", "Video", "Map"],
							mandatory: true,
						},
					],
				},
				{ ...mainLink },
				{ ...secondaryLink },
			],
		},

		{
			title: "config",
			fields: [
				{ ...anchor },
				{
					...themeSelector,
					options: getThemeOptions(["default", "default-alt"]),
				},
				{ ...verticalSpacing },
				{ ...separator },
			],
		},
	],

	default: {
		component: "IntroForm",
		title: {
			content: "Lorem Ipsum",
			tag: "h2",
		},
		subtitle: loremIpsumParagraph,
		content: loremIpsumParagraph,
		format: "form",
		visualContent: {
			image: { component: "Image" },
			video: { component: "Video" },
			map: { component: "Map" },
		},
		link1: { component: "Link" },
		link2: { component: "Link" },
		anchorID: null,
		subtheme: "default",
		verticalSpacing: "medium",
		separator: true,
	},

	thumbnails: {
		"1x": "/thumbnails/modules/IntroForm/thumbnail@1x.png",
		"2x": "/thumbnails/modules/IntroForm/thumbnail@2x.png",
	},
};

export default schema;
