import { BasicCardProps } from "@autoTypes";
import { Schema } from "@griddo/core";
import {
	content,
	heading,
	linkLite,
	loremIpsumParagraph,
} from "@schemas/presets";

const schema: Schema.Component<BasicCardProps> = {
	schemaType: "component",
	component: "BasicCard",
	displayName: "BasicCard",

	configTabs: [
		{
			title: "content",
			fields: [
				{
					type: "ComponentContainer",
					title: "Visual Content",
					key: "visualContent",
					whiteList: ["Image", "Video"],
					hideable: true,
					helptext: "Recommended minimum image size: 467x467px",
				},
				{ ...heading },
				{
					type: "TextField",
					title: "Additional text",
					key: "subtitle",
					hideable: true,
					placeholder: "Type a subtitle",
					humanReadable: true,
				},
				{ ...content },
				{
					...linkLite,
					hideable: false,
					mandatory: true,
				},
			],
		},
	],

	default: {
		component: "BasicCard",
		title: { content: "Lorem ipsum", tag: "h4" },
		subtitle: "Lorem ipsum",
		content: loremIpsumParagraph,
		link: { component: "LinkLite" },
		visualContent: {
			image: { component: "Image" },
			video: { component: "Video" },
		},
	},

	thumbnails: {
		"1x": "/thumbnails/templates/BasicCard/thumbnail@1x.png",
		"2x": "/thumbnails/templates/BasicCard/thumbnail@2x.png",
	},
};

export default schema;
