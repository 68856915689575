import { UIFields } from "@griddo/core";

export const content: UIFields.Wysiwyg = {
	type: "Wysiwyg",
	full: false,
	title: "Content",
	key: "content",
	placeholder: "Type a detail",
	hideable: true,
	isMockup: true,
	humanReadable: true,
};
