import { Schema } from "@griddo/core";

const schema: Schema.MenuItem = {
	link: {
		fields: [
			{
				type: "TextField",
				title: "Quote",
				key: "quote",
				helptext:
					"Only applies to root items in big screen resolution. Use underscores at the beginning and end of a word to highlight it. For example:  Lorem _ipsum_",
				humanReadable: true,
			},
			{
				type: "TextField",
				title: "Text for view all link",
				key: "viewAllText",
				helptext: "Only applies to root items. Default value is 'View all'.",
				humanReadable: true,
			},
		],
	},

	group: {
		fields: [
			{
				type: "TextField",
				title: "Quote",
				key: "quote",
				helptext:
					"Only applies to root items in big screen resolution. Use underscores at the beginning and end of a word to highlight it. For example:  Lorem _ipsum_",
				humanReadable: true,
			},
			{
				type: "TextField",
				title: "Text for view all link",
				key: "viewAllText",
				helptext: "Only applies to root items. Default value is 'View all'.",
				humanReadable: true,
			},
		],
	},
};

export default schema;
