import { HeroCarouselProps } from "@autoTypes";
import { Schema } from "@griddo/core";
import HeroCarouselSlide from "@schemas/components/HeroCarouselSlide";
import { anchor, animation } from "@schemas/presets";

const schema: Schema.Module<HeroCarouselProps> = {
	schemaType: "module",
	component: "HeroCarousel",
	category: "heros",
	displayName: "Hero Carousel",

	configTabs: [
		{
			title: "content",
			fields: [
				{
					type: "ComponentArray",
					title: "Slides",
					key: "slides",
					whiteList: ["HeroCarouselSlide"],
					contentType: "components",
					maxItems: 5,
					mandatory: true,
				},
			],
		},
		{
			title: "config",
			fields: [
				{ ...anchor },
				{
					...animation,
					options: [
						{ name: "none", value: "none", title: "No animation" },
						{ name: "fadeIn", value: "fadeIn", title: "Fade In" },
						{ name: "fadeInDown", value: "fadeInDown", title: "Fade In Down" },
					],
				},
				{
					type: "UniqueCheck",
					key: "breadcrumbsDesktop",
					title: "Breadcrumbs Desktop/Tablet",
					options: [{ title: "Display in tablet and desktop" }],
				},
				{
					type: "UniqueCheck",
					key: "breadcrumbs",
					title: "Breadcrumbs Mobile",
					options: [{ title: "Display in mobile" }],
				},
			],
		},
	],

	default: {
		component: "HeroCarousel",
		slides: [
			{ ...HeroCarouselSlide.default },
			{ ...HeroCarouselSlide.default },
			{ ...HeroCarouselSlide.default },
		],
		anchorID: null,
		animation: "none",
		breadcrumbsDesktop: false,
		breadcrumbs: false,
	},

	thumbnails: {
		"1x": "/thumbnails/modules/HeroCarousel/thumbnail@1x.png",
		"2x": "/thumbnails/modules/HeroCarousel/thumbnail@2x.png",
	},
};

export default schema;
