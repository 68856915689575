import { FooterProps } from "@autoTypes";
import { Schema } from "@griddo/core";
import { getThemeOptions, linkLite, themeSelector } from "@schemas/presets";

const schema: Schema.Footer<FooterProps> = {
	schemaType: "module",
	displayName: "Footer",
	component: "Footer",
	type: "footer",
	defaultNavigation: true,

	configTabs: [
		{
			title: "content",
			fields: [
				{
					title: "Footer title",
					type: "TextField",
					key: "title",
					mandatory: true,
				},
				{
					title: "Show SEO Menu",
					type: "RadioGroup",
					key: "showSeoMenu",
					hideable: true,
					options: [
						{ name: "yes", title: "Yes", value: true },
						{ name: "no", title: "No", value: false },
					],
				},
				{
					title: "Show Social Media",
					type: "RadioGroup",
					key: "showSocialMedia",
					mandatory: true,
					options: [
						{ name: "yes", title: "Yes", value: true },
						{ name: "no", title: "No", value: false },
					],
				},
				{
					key: "email",
					title: "Email",
					type: "TextField",
					validators: { format: "email" },
					mandatory: true,
				},
				{
					key: "phone",
					title: "Phone",
					type: "TextField",
					hideable: true,
				},
				{
					key: "newsletterText",
					title: "Newsletter text",
					type: "TextField",
					hideable: true,
					humanReadable: true,
				},
				{ ...linkLite, title: "Newsletter link" },
			],
		},

		{
			title: "config",
			fields: [
				{
					type: "UniqueCheck",
					title: "Set as default",
					key: "setAsDefault",
					options: [
						{
							title: "Set as default Footer",
						},
					],
				},
				{
					title: "Select SEO Menu",
					key: "seoMenu",
					type: "AsyncSelect",
					entity: "menu_containers",
				},
				{
					title: "Select Legal Menu",
					key: "legalMenu",
					type: "AsyncSelect",
					entity: "menu_containers",
					mandatory: true,
				},
				{
					...themeSelector,
					options: getThemeOptions(["default", "inverse"]),
				},
			],
		},
	],

	default: {
		component: "Footer",
		type: "footer",
		title: "Footer name",
		showSeoMenu: true,
		showSocialMedia: true,
		link: { component: "LinkLite" },
		setAsDefault: false,
		seoMenu: undefined,
		legalMenu: undefined,
		subtheme: "inverse",
	},

	thumbnails: {
		"1x": "/thumbnails/modules/Footer/thumbnail@1x.png",
		"2x": "/thumbnails/modules/Footer/thumbnail@2x.png",
	},
};

export default schema;
