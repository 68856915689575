import { CardCollectionProps } from "@autoTypes";
import { Schema } from "@griddo/core";
import BasicCard from "@schemas/components/BasicCard";
import {
	heading,
	content,
	link,
	anchor,
	themeSelector,
	verticalSpacing,
	separator,
	animation,
	loremIpsumParagraph,
	getThemeOptions,
} from "@schemas/presets";

const schema: Schema.Module<CardCollectionProps> = {
	schemaType: "module",
	component: "CardCollection",
	category: "collection",
	displayName: "Card Collection",

	configTabs: [
		{
			title: "content",
			fields: [
				{ ...heading },
				{ ...content, mandatory: true, hideable: false },
				{
					type: "ComponentArray",
					title: "Cards",
					key: "elements",
					whiteList: ["BasicCard"],
					contentType: "components",
					mandatory: true,
				},
				{ ...link },
			],
		},

		{
			title: "config",
			fields: [
				{ ...anchor },
				{
					type: "VisualUniqueSelection",
					title: "Layout",
					key: "layout",
					columns: 3,
					mandatory: true,
					options: [
						{
							value: "layout1",
							img: "/thumbnails/modules/CardCollection/Layouts/layout01.png",
						},
						{
							value: "layout2",
							img: "/thumbnails/modules/CardCollection/Layouts/layout02.png",
						},
						{
							value: "layout3",
							img: "/thumbnails/modules/CardCollection/Layouts/layout03.png",
						},
					],
				},
				{
					...themeSelector,
					options: getThemeOptions(["default", "default-alt"]),
				},
				{ ...verticalSpacing },
				{ ...separator },
				{ ...animation },
			],
		},
	],

	default: {
		component: "CardCollection",
		title: {
			content: "Lorem Ipsum",
			tag: "h2",
		},
		content: loremIpsumParagraph,
		link: { component: "Link" },
		layout: "layout2",
		elements: [
			{ ...BasicCard.default },
			{ ...BasicCard.default },
			{ ...BasicCard.default },
		],
		subtheme: "default",
		anchor: null,
		verticalSpacing: "medium",
		separator: false,
		animation: "none",
	},

	thumbnails: {
		"1x": "/thumbnails/modules/CardCollection/thumbnail@1x.png",
		"2x": "/thumbnails/modules/CardCollection/thumbnail@2x.png",
	},
};

export default schema;
