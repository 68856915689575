import { VideoProps } from "@autoTypes";
import { Schema } from "@griddo/core";

const schema: Schema.Component<VideoProps> = {
	schemaType: "component",
	component: "Video",
	displayName: "Video",

	configTabs: [
		{
			title: "content",
			fields: [
				{
					title: "Thumbnail type",
					type: "ConditionalField",
					key: "backgroundType",
					mandatory: true,
					options: [
						{
							name: "video",
							value: "video",
							title: "Video",
						},
						{
							name: "image",
							value: "image",
							title: "Image",
						},
					],
					fields: [
						{
							condition: "video",
							type: "TextField",
							key: "backgroundUrl",
							title: "Video URL",
							validators: { format: "URL" },
							mandatory: true,
						},
						{
							condition: "video",
							key: "openInModal",
							title: "Open video in a modal?",
							type: "ConditionalField",
							options: [
								{
									name: "yes",
									value: true,
									title: "Yes",
								},
								{
									name: "no",
									value: false,
									title: "No",
								},
							],
							fields: [
								{
									condition: true,
									type: "TextField",
									key: "url",
									title: "Video URL",
									validators: { format: "URL" },
									helptext: "Only if it is different from thumbnail video",
								},
							],
						},
						{
							condition: "image",
							type: "ImageField",
							key: "image",
							title: "Thumbnail image",
							mandatory: true,
						},
						{
							condition: "image",
							type: "TextField",
							key: "url",
							title: "Video URL",
							validators: { format: "URL" },
							mandatory: true,
						},
						{
							condition: "image",
							type: "UniqueCheck",
							title: "Open in modal",
							key: "openInModal",
							options: [
								{
									title: "Open in modal",
								},
							],
						},
					],
				},
				{
					title: "Veil percentage",
					type: "SliderField",
					key: "veil",
					step: 10,
					min: 0,
					max: 100,
				},
				{
					type: "UniqueCheck",
					key: "automaticSubtitles",
					title: "Subtitles",
					options: [{ title: "Add automatic subtitles" }],
				},
			],
		},
	],

	default: {
		component: "Video",
		backgroundType: "video",
		openInModal: false,
		veil: 30,
		automaticSubtitles: true,
	},

	thumbnails: {
		"1x": "/thumbnails/components/Video/thumbnail@1x.png",
		"2x": "/thumbnails/components/Video/thumbnail@2x.png",
	},
};

export default schema;
