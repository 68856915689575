import { BackgroundVideoProps } from "@autoTypes";
import { Schema } from "@griddo/core";

const schema: Schema.Component<BackgroundVideoProps> = {
	schemaType: "component",
	component: "BackgroundVideo",
	displayName: "Video",

	configTabs: [
		{
			title: "content",
			fields: [
				{
					type: "TextField",
					key: "url",
					title: "Video URL",
					validators: { format: "URL" },
					mandatory: true,
				},
				{
					title: "Veil percentage",
					type: "SliderField",
					key: "veil",
					step: 10,
					min: 0,
					max: 100,
				},
				{
					type: "UniqueCheck",
					key: "automaticSubtitles",
					title: "Subtitles",
					options: [{ title: "Add automatic subtitles" }],
				},
			],
		},
	],

	default: {
		component: "BackgroundVideo",
		veil: 30,
		automaticSubtitles: false,
	},

	thumbnails: {
		"1x": "/thumbnails/components/Video/thumbnail@1x.png",
		"2x": "/thumbnails/components/Video/thumbnail@2x.png",
	},
};

export default schema;
