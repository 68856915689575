import { VerticalTabsProps } from "@autoTypes";
import { Schema } from "@griddo/core";
import VerticalTab from "@schemas/components/VerticalTab";
import {
	anchor,
	themeSelector,
	verticalSpacing,
	animation,
	headingType,
	headingTextarea,
	content,
	loremIpsumParagraph,
	getThemeOptions,
} from "@schemas/presets";

const schema: Schema.Module<VerticalTabsProps> = {
	schemaType: "module",
	component: "VerticalTabs",
	category: "interactive",
	displayName: "Vertical Tabs",

	configTabs: [
		{
			title: "content",
			fields: [
				{ ...headingTextarea },
				{ ...headingType },
				{ ...content },
				{
					type: "ComponentArray",
					title: "Tabs",
					key: "tabs",
					whiteList: ["VerticalTab"],
					contentType: "components",
					mandatory: true,
				},
			],
		},

		{
			title: "config",
			fields: [
				{ ...anchor },
				{
					...themeSelector,
					options: getThemeOptions(["default", "default-alt"]),
				},
				{ ...verticalSpacing },
				{ ...animation },
			],
		},
	],

	default: {
		component: "VerticalTabs",
		title: "Lorem ipsum",
		tag: "h2",
		content: loremIpsumParagraph,
		tabs: [
			{ ...VerticalTab.default },
			{ ...VerticalTab.default },
			{ ...VerticalTab.default },
			{ ...VerticalTab.default },
			{ ...VerticalTab.default },
		],
		anchorID: null,
		subtheme: "default",
		verticalSpacing: "medium",
		animation: "none",
	},

	thumbnails: {
		"1x": "/thumbnails/modules/VerticalTabs/thumbnail@1x.png",
		"2x": "/thumbnails/modules/VerticalTabs/thumbnail@2x.png",
	},
};

export default schema;
