import { UIFields } from "@griddo/core";

export const subtitle: UIFields.Wysiwyg = {
	type: "Wysiwyg",
	full: false,
	title: "Subtitle",
	key: "subtitle",
	placeholder: "Type a subtitle",
	hideable: true,
	isMockup: true,
	humanReadable: true,
};

export const subtitleDecoration: UIFields.RadioGroup = {
	title: "Subtitle decoration",
	type: "RadioGroup",
	key: "decoration",
	options: [
		{ name: "yes", value: true, title: "Yes" },
		{ name: "no", value: false, title: "No" },
	],
	helptext: "This behavior will only affect the decoration in the subtitle",
	mandatory: true,
};
