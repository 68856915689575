import { Schema } from "@griddo/core";

export const INSIGHT_CATEGORY: Schema.CategoryContentType = {
	dataPacks: ["INSIGHTS"],
	title: "Insights category",
	local: false,
	translate: true,
	taxonomy: true,
	fromPage: false,
	editable: false,
};
