import { Schema } from "@griddo/core";

export const INSIGHTS: Schema.PageContentType = {
	dataPacks: ["INSIGHTS"],
	title: "Insights",
	local: false,
	fromPage: true,
	translate: true,
	taxonomy: false,
	schema: {
		templates: ["InsightsDetail"],
		fields: [
			{
				type: "TextField",
				title: "Title",
				from: "title",
				key: "title",
				mandatory: true,
			},
			{
				type: "DateField",
				title: "Date",
				from: "insightDate",
				key: "insightDate",
				indexable: true,
			},
			{
				type: "Wysiwyg",
				full: false,
				title: "Abstract",
				from: "abstract",
				key: "abstract",
			},
			{
				type: "MultiCheckSelect",
				title: "Insight Category",
				placeholder: "Insight category",
				from: "categories.insightCategories",
				key: "insightCategories",
				source: "INSIGHT_CATEGORY",
			},
			{
				type: "MultiCheckSelect",
				title: "Office",
				placeholder: "Office",
				from: "categories.office",
				key: "office",
				source: "OFFICES",
			},
			{
				type: "MultiCheckSelect",
				title: "Related partners",
				placeholder: "Related partners",
				from: "categories.relatedPartners",
				key: "relatedPartners",
				source: "PEOPLE",
			},
			{
				type: "MultiCheckSelect",
				title: "Practice areas",
				placeholder: "Practice areas",
				from: "categories.areas",
				key: "areas",
				source: "PRACTICE_AREA",
			},
			{
				type: "MultiCheckSelect",
				title: "Sectors",
				placeholder: "Sectors",
				from: "categories.sectors",
				key: "sectors",
				source: "SECTOR",
			},
			{
				type: "ImageField",
				title: "Image",
				from: "image",
				key: "image",
			},
		],
	},
};
