import { UIFields } from "@griddo/core";

export const separator: UIFields.RadioGroup = {
	title: "Show separator",
	type: "RadioGroup",
	key: "separator",
	options: [
		{ name: "yes", value: true, title: "Yes" },
		{ name: "no", value: false, title: "No" },
	],
	mandatory: true,
};
