import { FullImageProps } from "@autoTypes";
import { Schema } from "@griddo/core";

const schema: Schema.Component<FullImageProps> = {
	schemaType: "component",
	component: "FullImage",
	displayName: "FullImage",

	configTabs: [
		{
			title: "content",
			fields: [
				{
					title: "File",
					type: "ImageField",
					key: "imageField",
					helptext: "The image file.",
				},

				{
					title: "Alternative Text",
					type: "TextField",
					key: "alt",
					placeholder: "Type an alternative text for the image",
					helptext:
						"Set the alternative text for the image. If the image is used with a decorative rol, this text wil be ignored.",
				},
			],
		},

		{
			title: "Options",
			fields: [
				{
					type: "UniqueCheck",
					title: "Use veil",
					key: "useVeil",
					helptext: "The veil color is obtained from the primitive veil-1",
					options: [
						{
							title: "Use veil",
						},
					],
				},

				{
					title: "Quality",
					type: "SliderField",
					key: "quality",
					step: 10,
					min: 10,
					max: 90,
					helptext:
						"Set the quality of the image. The higher the quality, the bigger the file size.",
				},

				{
					title: "Loading",
					key: "loading",
					type: "RadioGroup",
					helptext:
						"Use this option to control the image loading behavior by the browser.",
					options: [
						{ name: "lazy", value: "lazy", title: "Lazy" },
						{ name: "eager", value: "eager", title: "Eager" },
					],
				},

				{
					title: "Decoding",
					key: "decoding",
					type: "RadioGroup",
					helptext:
						"Represents a hint given to the browser on how it should decode the image.",
					options: [
						{ name: "auto", value: "auto", title: "Auto" },
						{ name: "async", value: "async", title: "async" },
						{ name: "sync", value: "sync", title: "sync" },
					],
				},

				{
					title: "Fetch priority",
					key: "fetchpriority",
					type: "RadioGroup",
					helptext:
						"Represents a hint given to the browser on how it should prioritize the fetch of the image relative to other images.",
					options: [
						{ name: "auto", value: "auto", title: "Auto" },
						{ name: "low", value: "low", title: "low" },
						{ name: "high", value: "high", title: "high" },
					],
				},
			],
		},

		{
			title: "Sizing",
			fields: [
				{
					type: "NumberField",
					key: "ratio",
					title: "Ratio",
				},
				{
					title: "Crop type",
					type: "RadioGroup",
					key: "crop",
					options: [
						{
							name: "cover",
							value: "cover",
							title: "Cover",
						},

						{
							name: "contain",
							value: "contain",
							title: "Contain",
						},

						{
							name: "fill",
							value: "fill",
							title: "Fill",
						},
					],
				},
			],
		},
	],

	default: {
		component: "FullImage",
		imageField: undefined,
		alt: "",
		useVeil: false,
		quality: 70,
		loading: "lazy",
		decoding: "async",
		fetchpriority: "auto",
		crop: "cover",
	},
};

export default schema;
