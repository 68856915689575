import { CypherCardProps } from "@autoTypes";
import { Schema } from "@griddo/core";

const schema: Schema.Component<CypherCardProps> = {
	schemaType: "component",
	component: "CypherCard",
	displayName: "Cypher Card",

	configTabs: [
		{
			title: "content",
			fields: [
				{
					type: "TextField",
					key: "prefix",
					title: "Prefix",
					hideable: true,
				},
				{
					type: "TextField",
					key: "number",
					title: "Number",
					mandatory: true,
					isMockup: true,
					placeholder: "Type a number",
				},
				{
					type: "TextField",
					key: "suffix",
					title: "Suffix",
					hideable: true,
				},
				{
					type: "TextField",
					key: "superindex",
					title: "Superindex",
					hideable: true,
				},
				{
					type: "TextField",
					key: "title",
					title: "Title",
					hideable: true,
					humanReadable: true,
				},
			],
		},
	],

	default: {
		component: "CypherCard",
		prefix: "",
		number: "0",
		suffix: "",
		superindex: "",
		title: "",
	},
};

export default schema;
