import { Schema } from "@griddo/core";

export const NEWS: Schema.PageContentType = {
	dataPacks: ["GRIDDO_PACK"],
	title: "News",
	local: true,
	fromPage: true,
	translate: true,
	taxonomy: false,
	schema: {
		templates: ["NewsDetail"],
		fields: [
			{
				type: "TextField",
				key: "title",
				title: "Title",
				from: "title",
			},

			{
				type: "ImageField",
				title: "Image",
				key: "image",
				from: "image",
			},

			{
				type: "TextField",
				title: "Abstract",
				from: "abstract",
				key: "abstract",
			},

			{
				title: "News Cateogory",
				type: "MultiCheckSelect",
				key: "category",
				from: "category",
				source: "NEWS_CATEGORY",
				placeholder: "A place holder",
			},

			{
				title: "section",
				type: "ComponentArray",
				from: "mainSection",
				key: "mainSection",
				whiteList: ["MainHero", "Collection"],
				contentType: "modules",
			},
		],
	},
};
