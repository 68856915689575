import { BasicTemplateProps } from "@autoTypes";
import { Schema } from "@griddo/core";

const schema: Schema.Template<BasicTemplateProps> = {
	schemaType: "template",
	displayName: "Basic",
	component: "BasicTemplate",
	type: { label: "Static", value: "static" },

	dimensions: [],

	content: [
		{
			type: "ComponentArray",
			title: "Hero Section",
			maxItems: 1,
			whiteList: ["BasicHero", "HeroCarousel"],
			key: "heroSection",
			contentType: "modules",
		},

		{
			type: "ComponentArray",
			title: "Main Section",
			contentType: "modules",
			whiteList: [
				"AccordionCollection",
				"BasicContent",
				"CardCollection",
				"BasicText",
				"CypherCollection",
				"FAQCollection",
				"FeaturedFacts",
				"IntroForm",
				"Navigation",
				"Quote",
				"TextCarousel",
				"VerticalTabs",
				"Widget",
				"Wysiwyg",
			],
			key: "mainSection",
		},
	],

	default: {
		type: "template",
		templateType: "BasicTemplate",
		heroSection: {
			component: "Section",
			name: "Hero Section",
			modules: [],
		},
		mainSection: {
			component: "Section",
			name: "Main Section",
			modules: [],
		},
	},

	thumbnails: {
		"1x": "/thumbnails/templates/BasicTemplate/thumbnail@1x.png",
		"2x": "/thumbnails/templates/BasicTemplate/thumbnail@2x.png",
	},
};

export default schema;
